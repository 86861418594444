import React from "react"
import { graphql } from "gatsby"
import yaml from "js-yaml"
import Layout from "../../components/Layout"
import LayoutPub from "../../components/LayoutPub"
import DateTitle from "../../components/DateTitle"
import { formatDate, dateOffset } from "../../util"
import EventGroup from "../../components/EventGroup"
import EndMatter from "../../components/EndMatter"
import ReactPlayer from 'react-player/lazy'
import Icon from "../../components/Icon"
import { isBrowser } from 'react-device-detect';

import classNames from "classnames/bind"
import styles from "./styles.module.scss"


export default function PageTemplate({ pageContext: { language, availableLanguages }, data: { mdx } }) {

  const cx = classNames.bind(styles);

	return(
		<Layout
			lang={language}
			availableLanguages={availableLanguages}
			mdx={mdx}
			showFullWidthCover={!mdx.frontmatter.hideCover}
			collection={mdx.fields.collection}
		>
			{
				mdx.wordCount.words === 0
				? <DateTitle
						frontmatter={mdx.frontmatter}
						lang={language}
					/>
				: <LayoutPub
						frontmatter={mdx.frontmatter}
						collection={mdx.fields.collection}
						body={mdx.body}
						authors={mdx.authors}
						lang={language}
						hideCover={!mdx.frontmatter.hideCover}
					/>
			}
			{mdx.frontmatter.liveUrl && isBrowser &&  
        <div style={{aspectRatio: "16/9"}}>
          <ReactPlayer url={mdx.frontmatter.liveUrl} width="100%" height="100%" controls={true} />
        </div>
      }
			{(mdx.wordCount.words > 0 || (mdx.frontmatter.liveUrl && isBrowser)) && 
				<h2>{language === "th" ? "กำหนดการ" : "Schedule"}</h2>
			}
			{
				mdx.frontmatter.program &&
				<div className={cx("program")}>
					<a href={mdx.frontmatter.program} target="_blank" rel="nofollow noopener noreferrer">
						<span className="inline-svg"><Icon icon="page" /></span> {language === "th" ? "สูจิบัตร" : "Program"}
					</a>
				</div>
			}
			{
				mdx.frontmatter.data.map((day_data, i) => {
					const events = yaml.load(day_data)
					return(
						<React.Fragment key={`day${i}`}>
							<div key={`$events${i}`}>
								{mdx.frontmatter.data.length > 1 && <h4>{formatDate(dateOffset(mdx.frontmatter.date, i), language)}</h4>}
								<EventGroup
									date={dateOffset(mdx.frontmatter.date, i)}
									events={events}
									language={language}
									availableLanguages={availableLanguages}
									className={"main-table"}
									notime={mdx.frontmatter.notime}
									details={mdx.frontmatter.details}
								/>
							</div>
							{mdx.frontmatter.endNote &&
								<div className={cx("endnote")}>{mdx.frontmatter.endNote}</div>
							}
						</React.Fragment>
					)
				})
			}
      <EndMatter collection={mdx.frontmatter.collection} fullText={mdx.frontmatter.fullText} endLinks={mdx.frontmatter.endLinks} video={mdx.frontmatter.video} topics={mdx.frontmatter.topics} tags={mdx.frontmatter.tags} shareBottom={true} showDisclaimer={false} lang={language} />
			{/* {mdx.wordCount.words === 0 &&
				<EndMatter collection={mdx.frontmatter.collection} fullText={mdx.frontmatter.fullText} endLinks={mdx.frontmatter.endLinks} video={mdx.frontmatter.video} topics={mdx.frontmatter.topics} tags={mdx.frontmatter.tags} shareBottom={true} showDisclaimer={false} lang={language} />
			} */}
		</Layout>
	)
}

export const pageQuery = graphql`
	query ConferenceQuery($id: String) {
		mdx(id: { eq: $id }) {
			body
			wordCount {
				words
			}
			frontmatter {
        date
				endDate
        title
        location
				locationUrl
				liveUrl
				endLinks {
					icon
					label
					link
				}
				data
				notime
				endNote
				program
				authors
				cover {
					publicURL
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
						thumbnail: gatsbyImageData(layout: CONSTRAINED, width: 160, height: 160)
					}
				}
				hideCover
				details {
					id
					mdx
				}
      }
      fields {
				draft
				collection
				shorturl
				originalPath
      }
			authors {
				frontmatter {
					memberid
					title
					org
					image {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 200, height: 200)
						}
					}
					exclude
				}
				fields {
					originalPath
					lang
				}
			}
		}
	}
`