import React from "react"
import Link from "../../components/IntlLink"
import Icon from "../../components/Icon"
import { getString, dontBreak, checkTime } from "../../util"
import { useStaticQuery, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx";

import classNames from "classnames/bind"
import styles from "./styles.module.scss"


export default function EventGroup({ date, events, language, availableLanguages, className, notime, details }) {

  const cx = classNames.bind(styles);

  if (availableLanguages && availableLanguages.length === 1) {
    language = availableLanguages[0]
  }

  const data = useStaticQuery(graphql`
		query {
			members: allMdx(filter: {fields: {collection: {eq: "members"}}}) {
        nodes {
          frontmatter {
            memberid
            org
            title
            exclude
          }
          fields {
            lang
            originalPath
          }
        }
      }
		}
	`)
  const members = data.members

  function getAuthor(author, j, mod=false) {
    let name
    let org
    let info
    let exclude = false
    let originalPath = ""
    const tmp = members.nodes.filter(x => (x.frontmatter.memberid === author.name || (author.name && author.name.en && x.frontmatter.memberid === author.name.en)) && x.fields.lang === language)
    
    if (tmp.length > 0) {
      name = tmp[0].frontmatter.title
      org = tmp[0].frontmatter.org
      originalPath = tmp[0].fields.originalPath
      exclude = tmp[0].frontmatter.exclude
    }
    else {
      name = getString(author.name, language)
    }
    
    // override affiliations
    if (author.org)
      org = getString(author.org, language)

    if (author.info)
      info = getString(author.info, language)

    if (!name) {
      // console.error(author)
      // console.log(name)
      return <React.Fragment>???</React.Fragment>
    }

    name = dontBreak(name)

    if (mod) {
      return(
        <React.Fragment>
          {name} ({org})
        </React.Fragment>
      )
    }
    else {
      return(
        <div className={cx("author")} key={`author${j}`}>
          <div className={cx("name")} data-typesense-field="body"><Link to={!exclude && originalPath}>{name}</Link></div>
          <div className={cx("org")}>
            <div>
              <span data-typesense-field="body">{org}</span>
              <Links data={author} />
            </div>
            <span data-typesense-field="body">{info}</span>
          </div>
        </div>
      )
    }
  }

  function getGroup(g, i) {
    let o
    let p
    if ('group' in g) {
      // regular
      o = g.group
      p = g.people
    }
    else {
      let key = Object.keys(g)[0]
      p = g[key]
      if (["author", "discussant", "panelist", "moderator", "mod", "presenter"].includes(key))
        key += "s"
      const xxx = {
        by: {th: "โดย", en: "by"},
        authors: {th: "ผู้เขียน", en: "Author"},
        presenters: {th: "ผู้นำเสนอ", en: "Presenter"},
        discussants: {th: "ผู้วิจารณ์", en: "Discussant"},
        panelists: {th: "ผู้ร่วมเสวนา", en: "Panelist"},
        moderators: {th: "ผู้ดำเนินการเสวนา", en: "Moderator"},
        mods: {th: "ประธาน", en: "Moderator"},
        lead: {th: "ผู้ร่วมเสวนาหลัก", en: "Lead Discussant"},
        chair: {th: "ประธาน", en: "Chair"},
      }
      o = xxx[key]
      if (p.length > 1 && key !== "by") {
        o.en += "s"
      }
    }
    try {
      return(
        <div className={cx("peopleGroup", {notime: notime})} key={`group${i}`}>
          <div>{getString(o, language)}</div>
          <div>{p.map((author, j) => getAuthor(author, j))}</div>
        </div>
      )
    } catch (e) {
      console.error(p)
    }
  }

  function LinkItem({ to, icon, alt }) {
    if (to[0] === '/') {
      return(
        <Link to={to} language={language}>
          <Icon icon={icon} alt={alt} />
        </Link>
      )
    }
    return(
      <a href={to} target="_blank" rel="nofollow noopener noreferrer" className="left-margin">
        <Icon icon={icon} alt={alt} />
      </a>
    )
  }

  function Links({ data }) {
    return(
      <React.Fragment>
        <div className={cx("links")}>
          {data.doc && (!data.docTime || checkTime(date, data.docTime)) &&
            <>
            {
              typeof(data.doc) === "string"
              ? <LinkItem to={getString(data.doc, language)} icon="page" alt="Document" />
              : Object.keys(data.doc).map(d => <LinkItem to={getString(data.doc[d], language)} icon="page" alt={d} />)
            }
            </>
          }
          {data.slides && (!data.slidesTime || checkTime(date, data.slidesTime)) &&
            <LinkItem to={getString(data.slides, language)} icon="slide" alt="Presentation" />
          }
          {data.video && (!data.videoTime || checkTime(date, data.videoTime)) &&
            <a href={data.video} target="_blank" rel="nofollow noopener noreferrer" className="left-margin">
              <Icon icon="youtube" alt="Video" />
            </a>
          }
        </div>
      </React.Fragment>
    )
  }

  function getDetails(eventDetails) {

    // process details for that particular event

    let mdxDetails = false
    if (eventDetails && eventDetails[0] === "@") {
      let i = details.map(x => x.id).indexOf(eventDetails.substring(1))
      // check if need to pull mdx details
      if (i > -1) {
        mdxDetails = true
        return <MDXRenderer>{details[i].mdx}</MDXRenderer>
      }
    }
    if (!mdxDetails) {
      const tmp = getString(eventDetails, language)
      return <p>{tmp.split("\n").map((line, k) => <React.Fragment key={k}>{line}<br /></React.Fragment>)}</p>
    }
    
  }

  function Event({ event }) {
    return(
      <tr>
        {!notime && <td className={cx("time")}>{event.time}</td>}
        <td className={cx("event")}>
          <div className={cx("title", {notime: notime})}>
            <Link to={event.link}><span data-typesense-field="body">{getString(event.title, language)}</span></Link>
            <Links data={event} />
          </div>
          {event.details &&
            <div data-typesense-field="body">
              {getDetails(event.details)}
            </div>
          }
          {event.peopleGroup &&
            event.peopleGroup.map((group, i) => getGroup(group, i))
          }
        </td>
      </tr>
    )
  }

  return(
    <table className={className}>
      <tbody>
        {events.map((event, i) => {
          if (event.type === "event") {
            return(
              <Event event={event} key={`event${i}`}/>
            )
          }
          else if (event.type === "part") {
            return(
              <React.Fragment key={`event${i}`}>
                <tr className={cx("spacer")}><td /></tr>
                <tr className={cx("partRow")}>
                  <td colSpan="2" className={cx("part")}>
                    <div className={cx("partTitle")}>
                      <div className={cx("partTop")}>
                        <div className={cx("partText")} data-typesense-field="body">{getString(event.name || event.title, language)}</div>
                        {event.mod &&
                          <div className={cx("mod")} data-typesense-field="body">
                            {language === "th" ? "ประธาน: " : "Moderator: "}
                            {getAuthor(event.mod, 0, true)}
                          </div>
                        }
                        {event.chair &&
                          <div className={cx("mod")} data-typesense-field="body">
                            {language === "th" ? "ประธาน: " : "Chair: "}
                            {getAuthor(event.chair, 0, true)}
                          </div>
                        }
                        {event.modDiscussion &&
                          <div className={cx("mod")} data-typesense-field="body">
                            {language === "th" ? "ผู้ดำเนินการเสวนา: " : "Moderator: "}
                            {!event.modDiscussion.length &&
                              getAuthor(event.modDiscussion, 0, true)
                            }
                            {event.modDiscussion.length &&
                              // need to do this because getAuthor returns JSX element, not string
                              // see, e.g. http://localhost:8000/forums/2022/06/poverty-and-household-debt/
                              <>
                              {
                                event.modDiscussion.map((m, i) => [getAuthor(m, 0, true), i !== event.modDiscussion.length - 1 && <> / </>])
                              }
                              </>
                            }
                          </div>
                        }
                        {event.details &&
                          <div className={cx("details")} data-typesense-field="body">{getDetails(event.details)}</div>
                        }
                      </div>
                      <Links data={event} />
                    </div>
                      <EventGroup
                        date={date}
                        events={event.events}
                        language={language}
                        availableLanguages={availableLanguages}
                        className={cx("partTable")}
                        notime={notime}
                        details={details}
                      />
                  </td>
                </tr>
                <tr className={cx("spacer")}><td /></tr>
              </React.Fragment>
            )
          }
          else {
            let o
            if (event.type === "misc") {
              o = event.title
            }
            else {
              const xxx = {
                registration: {th: "ลงทะเบียน", en: "Registration"},
                break: {th: "พักรับประทานอาหารว่าง", en: "Break"},
                lunch: {th: "พักรับประทานอาหารกลางวัน", en: "Lunch break"},
              }
              o = xxx[event.type]
            }	
            return(
              <tr key={`event${i}`}>
                <td className={cx("time")}>{event.time}</td>
                <td>{getString(o, language)}</td>
              </tr>
            )
          }
        })}
      </tbody>
    </table>
  )
}